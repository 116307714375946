import React, { useContext, Fragment } from "react";
import { Link } from "gatsby";
import { useIntl } from "react-intl";

import Context from "./context";
import Newsletter from "./newsletter";
import Logo from "./logo";

const Footer = () => {
  const { data } = useContext(Context);
  const intl = useIntl();
  const allPages = data.allContentfulPage.nodes;
  const { lat, lon, address } = data.contentfulHotel;

  function getPageByCategory(category) {
    return allPages.find((page) => page.category === category);
  }

  function getPageById(id) {
    return allPages.find((page) => page.contentful_id === id);
  }

  return (
    <footer className="footer">
      <div className="footer-container">
        <Newsletter />
        <div className="footer-legal">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <a
                  className="footer-logo"
                  href={`https://www.ace-hotel.com/${intl.locale}/`}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="ACE Hôtel"
                >
                  <Logo variant="cropped" />
                </a>
              </div>
              <div className="col-lg-4 mt-4 mt-lg-0">
                <p>
                  {`ACE Hôtel${
                    data.contentfulHotel.spirit.slug === "travel"
                      ? " Travel"
                      : ""
                  } ${data.contentfulHotel.title}`}
                  <br />
                  {data.contentfulHotel.address.split(",").map((line, i) => (
                    <Fragment key={i}>
                      {line}
                      <br />
                    </Fragment>
                  ))}
                  {data.contentfulHotel.phone && (
                    <a
                      className="link link-tel"
                      href={`tel:${data.contentfulHotel.phone.replace(
                        /\s/g,
                        ""
                      )}`}
                    >
                      {data.contentfulHotel.phone}
                    </a>
                  )}
                </p>
                <p>
                  <a
                    href={`https://www.google.com/maps/dir/?api=1&origin=current+location&destination=ace+hotel+${address
                      .split(" ")
                      .join("+")}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>
                      {intl.formatMessage({ id: "google-itinerary" })}
                    </strong>
                  </a>
                </p>
                <p className="d-lg-none">
                  <a
                    href={`https://www.waze.com/ul?ll=${lat}%2C${lon}&navigate=yes&zoom=17`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>
                      {intl.formatMessage({ id: "waze-itinerary" })}
                    </strong>
                  </a>
                </p>
                {data.contentfulHotel.business_url && (
                  <p>
                    <a
                      className="link"
                      href={`${data.contentfulHotel.business_url}/review`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {intl.formatMessage({ id: "review-link" })}
                    </a>
                  </p>
                )}
              </div>
              <div className="col-lg-3 mt-10 mt-lg-0">
                <ul>
                  <li>
                    <Link
                      to={`/${intl.locale}/${
                        getPageByCategory("Contact").slug
                      }/`}
                    >
                      {getPageByCategory("Contact").title}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/${intl.locale}/${
                        getPageByCategory("Carrières").slug
                      }/`}
                    >
                      {getPageByCategory("Carrières").title}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/${intl.locale}/${
                        getPageById("4IiYKqQohEKooIOcERUPQt").slug
                      }/`}
                    >
                      {getPageById("4IiYKqQohEKooIOcERUPQt").title}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/${intl.locale}/${
                        getPageById("6jKbs0agD77D99J5nLTR7K").slug
                      }/`}
                    >
                      {getPageById("6jKbs0agD77D99J5nLTR7K").title}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 mt-10 mt-lg-0">
                <div className="footer-credits">
                  <p>© {new Date().getFullYear()}, ACE Hôtel</p>
                  <p>
                    {intl.formatMessage({ id: "credit" })}{" "}
                    <a
                      href="https://ultro.fr/"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Ultrō"
                    >
                      <svg
                        width="36"
                        height="12"
                        viewBox="0 0 36 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0)">
                          <path d="M34.4228 0H28.4391V2.15788H34.4228V0Z" />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.28697 7.93447C5.28697 8.74659 4.80977 9.23388 4.08264 9.23388C3.73425 9.23388 3.46153 9.11015 3.25705 8.85489C3.05252 8.60738 2.94651 8.29803 2.94651 7.93447V3.35571H5.57408e-05V8.4759C-0.00752683 10.1156 0.75751 11.9796 2.90102 11.9796C3.77206 11.9796 4.68101 11.5851 5.27939 10.7576H5.30213V11.8095H8.23343V3.35571H5.28702V7.93447H5.28697Z"
                          />
                          <path d="M12.5541 0H9.57739V11.8607H12.5541V0Z" />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.921 0H15.0428L15.0344 3.40704H13.4059V5.8975H14.9586V8.80561C14.9586 9.82653 15.254 10.6155 15.8448 11.1646C16.428 11.7215 17.2082 11.9999 18.1853 11.9999C18.7988 11.9999 19.3214 11.9226 19.7532 11.7601V9.42432C19.3669 9.45524 19.1094 9.47072 18.9806 9.47072C18.2686 9.47072 17.9126 9.14588 17.9126 8.48846V5.89745H19.829V3.40704H17.9126L17.921 0Z"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M25.1156 3.53844C24.6536 3.70089 24.1916 4.09531 23.881 4.629H23.8583V3.40692H20.9346V11.8606H23.8886V8.38015C23.8886 6.94927 24.6006 6.23001 26.017 6.23001C26.2518 6.23001 26.4942 6.25324 26.7365 6.29959L26.7441 3.38374C26.5547 3.34508 26.3729 3.32959 26.1987 3.32959C25.8882 3.32959 25.5701 3.38374 25.1156 3.53844Z"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M34.6442 4.34221C33.8186 3.59198 32.6673 3.12793 31.463 3.12793C30.2587 3.12793 29.0998 3.59973 28.259 4.37319C27.4107 5.14665 26.8654 6.30678 26.8654 7.55204C26.8654 8.82047 27.388 9.96517 28.206 10.7309C29.024 11.5043 30.1829 11.9684 31.4024 11.9684C32.637 11.9684 33.8035 11.4811 34.6366 10.7C35.4698 9.92651 36.0001 8.75089 36.0001 7.48246C36.0001 6.23715 35.4698 5.09245 34.6442 4.34221ZM32.5571 8.82119C32.2466 9.16926 31.8678 9.33939 31.421 9.33939C30.9741 9.33939 30.5878 9.16926 30.2773 8.82119C29.9667 8.48087 29.8076 8.0787 29.8076 7.61465C29.8076 7.15059 29.9591 6.74838 30.2697 6.4081C30.5802 6.07552 30.9589 5.90539 31.4134 5.90539C31.8678 5.90539 32.2466 6.07552 32.5571 6.4081C32.8676 6.74843 33.0191 7.15059 33.0191 7.61465C33.0191 8.0787 32.8677 8.48087 32.5571 8.82119Z"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="36" height="12" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
